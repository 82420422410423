import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import UserManualsNavBar from '../../components/UserManualsNavBar'
import PedicureSpaChairSpecs from '../../components/PedicureSpaChairSpecs'
import VipChair from '../../images/vip-chair/vip.png'
import ChairDisplay from '../../components/ChairDisplay'
import VIPUserManual from '../../files/VIP-Chair-System-Diagram-Expansion.pdf'

const VipPage = () => (
  <Layout>
    <SEO
      title="VIP User Manual"
      description="Read and Download your VIP user manual"
      meta={[
        {
          name: 'keywords',
          content:
            'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
        }
      ]}
    />
    <UserManualsNavBar />
    <ChairDisplay imgLink={VipChair} fileLink={VIPUserManual} model="VIP" />
    <PedicureSpaChairSpecs />
  </Layout>
)

export default VipPage
